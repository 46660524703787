/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"
import anime from "animejs"
import Content, { Inner } from "../elements/Content"
import PlaneOne from "../images/plane1.svg"
import PlaneTwo from "../images/plane2.svg"
import PlaneThree from "../images/plane3.svg"
import blueBlockStyle from "../styles/blueBlock"

const ContentWrapper = styled(Content)`
  ${tw`bg-grey-lightest overflow-hidden py-0 md:py-8`};
  ${props => props.style}
  ${props => (props.isDark ? blueBlockStyle : tw`bg-grey-lightest`)};
  ${props =>
    !props.isDark ? tw`min-h-400 lg:min-h-600` : tw`min-h-200 lg:min-h-300`}
`

const InnerWrapper = styled(Inner)`
  ${tw`self-end py-16 lg:py-12 lg:text-center`};

  /* TODO: find a better way to set initial styles for all children */
  & > * {
    opacity: 0;
  }
`

const PlaneWrapper = styled(Inner)`
  ${tw`self-stretch flex`};
`

const PlaneOneWrapper = styled.div`
  ${tw`absolute opacity-0`};
  ${tw`w-full sm:w-3/4 lg:w-1/2`};
  right: 0;
  top: 0;

  @media (min-width: 601px) {
    right: 10%;
    top: -5%;
  }
`

const PlaneTwoWrapper = styled.div`
  ${tw`absolute  opacity-0`};
  ${tw`w-full md:w-3/4 lg:w-1/2`};
  right: -5%;
  bottom: -10%;

  @media (min-width: 601px) {
    bottom: -20%;
  }
`

const Human = styled.div`
  ${tw`absolute  opacity-0`};
  ${tw`w-full md:w-3/4 lg:w-1/2`};
  right: -55%;
  bottom: -30%;

  @media (min-width: 601px) {
    right: -40%;
  }

  @media (min-width: 901px) {
    right: -25%;
  }

  @media (min-width: 1201px) {
    right: -25%;
    bottom: -40%;
  }
`

const HeroIndex = ({ children, isDark, isBlog }) => {
  return (
    <PlaneWrapper>
      <PlaneOneWrapper className="planeone">
        <PlaneOne />
      </PlaneOneWrapper>
      <PlaneTwoWrapper className="planetwo">
        <PlaneTwo />
      </PlaneTwoWrapper>

      <Human className="planethree">
        <PlaneThree />
      </Human>

      <HeroInner isDark={isDark} isBlog={isBlog}>
        {children}
      </HeroInner>
    </PlaneWrapper>
  )
}

const HeroInner = ({ children, isStandalone }) => {
  return (
    <InnerWrapper
      className="text-titles"
      isStandalone={isStandalone}
      condensed={isStandalone}
    >
      {children}
    </InnerWrapper>
  )
}

const Hero = ({ children, isIndex, isDark, isBlog, style }) => {
  useEffect(() => {
    const tl = anime.timeline({
      easing: "easeOutElastic(5, 2)",
      duration: 1250,
    })
    if (isIndex) {
      // TODO: use React refs instead of class names
      tl.add({
        targets: [".planeone", ".planetwo", ".planethree"],
        translateY: ["100vh", "0"],
        opacity: ["1", "1"],
        delay(el, i) {
          return i * 295
        },
      })
      tl.add(
        {
          targets: ".text-titles > *",
          easing: "easeOutElastic(5, 2)",
          translateY: ["50%", "0"],
          opacity: ["0", "1"],
          delay(el, i) {
            return i * 175
          },
        },
        "-=750"
      )
    } else {
      tl.add({
        targets: ".text-titles > *",
        easing: "easeOutElastic(5, 2)",
        translateY: ["10%", "0"],
        opacity: ["0", "1"],
        delay(el, i) {
          return i * 275
        },
      })
    }

    return () => {
      return tl.restart
    }
  }, [isIndex])

  return (
    <ContentWrapper
      className="main-wrapper"
      isDark={isDark}
      isBlog={isBlog}
      style={style}
    >
      {isIndex ? (
        <HeroIndex isDark={isDark}>{children}</HeroIndex>
      ) : (
        <HeroInner isStandalone>{children}</HeroInner>
      )}
    </ContentWrapper>
  )
}

export default Hero

Hero.defaultProps = {
  IsIndex: false,
  IsDark: false,
  IsBlog: false,
  style: {},
}

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  IsIndex: PropTypes.bool,
  IsDark: PropTypes.bool,
  IsBlog: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.any,
}
