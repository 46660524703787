import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const LinkTo = ({ children, ...props }) => {
  const { href, style, className } = props

  return (
    <Link
      style={style}
      className={className}
      to={href}
      duration={0.55}
      hex="#07141d"
      {...props}
    >
      {children}
    </Link>
  )
}

LinkTo.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
}

LinkTo.defaultProps = {
  style: {},
  className: undefined,
}

export default LinkTo
