/* eslint-disable react/prop-types */
import React from "react"
import styled, { css } from "styled-components"
import tw from "tailwind.macro"
import line from "../images/icons/line.svg"

const textSizeLargest = css`
  ${tw`text-3xl lg:text-5xl font-serif leading-tight`};
`

const textSizeLarger = css`
  ${tw`text-2xl lg:text-3xl font-serif leading-tight`};
`

const textSizeLarge = css`
  ${tw`text-lg lg:text-2xl font-sans`};
`

const textSizeBase = css`
  ${tw`text-base lg:text-lg font-sans`};
`

const textSizeSmall = css`
  ${tw`text-sm font-sans`};
`

const textStyleLargest = css`
  ${textSizeLargest};
  ${tw`text-grey-darkest`};
`

const textStyleLarger = css`
  ${textSizeLarger};
  ${tw`font-semibold text-grey-darkest`};
`

const textStyleLarge = css`
  ${textSizeLarge};
  ${tw`font-light text-grey-darkest leading-loose`};
`

const textStyleBase = css`
  ${textSizeBase};
  ${tw`text-grey-darkest leading-loose`};
`

const textStyleSmall = css`
  ${textSizeSmall};
  ${tw`text-grey-dark font-semibold uppercase`};
`

const headingStyle = css`
  ${textStyleLargest};
  ${tw`relative m-0 py-3`};
`

const headingShadowStyle = css`
  text-shadow: -3px -3px 0 rgba(255, 255, 255, 0.82),
    3px -3px 0 rgba(255, 255, 255, 0.82), -3px 3px 0 rgba(255, 255, 255, 0.82),
    3px 3px 0 rgba(255, 255, 255, 0.82);
`

const Title = styled.h1`
  ${headingStyle};
  ${headingShadowStyle};
`

const H1 = styled.h1`
  ${headingStyle};
  ${headingShadowStyle};
`

const H1Light = styled(H1)`
  ${tw`text-grey-lightest inline-block`};
  text-shadow: -3px -3px 0 rgba(0, 0, 0, 0.02), 3px -3px 0 rgba(0, 0, 0, 0.02),
    -3px 3px 0 rgba(0, 0, 0, 0.02), 3px 3px 0 rgba(0, 0, 0, 0.02);
`

const H2Style = css`
  ${textStyleLarger};
`

const H2 = styled.h2`
  ${H2Style};
`

const H2Light = styled(H2)`
  ${tw`text-white`};
`

const H3Style = css`
  ${textStyleLarge};
`

const H3 = styled.h3`
  ${H3Style};
`

const H4Style = css`
  ${textStyleBase};
  ${tw`uppercase font-semibold`};
`

const H4 = styled.h4`
  ${H4Style};
`

const PStyle = css`
  ${textStyleBase};
`

const Light = styled.span`
  ${tw`text-white`};
`

const Highlight = styled.span`
  ${tw`text-pink`};
`

const P = styled.p`
  ${PStyle};
  ${Highlight} {
    color: inherit;
    font-weight: bold;
  }
`

const PLight = styled(P)`
  ${tw`text-white`};
`

const WrapTextBlock = styled.span`
  ${tw`xl:block`}
`

const Strong = styled.span`
  ${tw`font-bold`}
`

const QuoteIcon = styled(line)`
  ${tw`text-pink -mb-8`};
  width: 40px;
  height: 40px;
`

const QuoteText = styled.h4`
  ${tw`text-xl font-sans font-semibold leading-normal text-grey-dark mb-8 relative inline-block`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`

const QuoteWrapper = styled.div`
  ${tw`relative`};
`

const Quote = ({ children }) => (
  <QuoteWrapper>
    <QuoteIcon />
    <QuoteText>{children}</QuoteText>
  </QuoteWrapper>
)

const QuoteGroup = styled.div`
  ${tw`lg:flex`};

  ${QuoteWrapper} {
    ${tw`lg:ml-6 lg:w-full`};

    &:first-child {
      ${tw`lg:text-right lg:mr-6 lg:ml-0`};
    }
  }
`

const Impact = styled.p`
  ${tw`text-3xl lg:text-4xl relative text-white font-serif `};
`

const BigTitleLight = styled(H1Light)`
  ${tw`text-4xl sm:text-5xl md:text-5xl lg:text-6xl`}
`

export {
  textSizeSmall,
  textSizeBase,
  textSizeLarge,
  textSizeLarger,
  textStyleSmall,
  textStyleBase,
  textStyleLarge,
  textStyleLarger,
  Title,
  H1,
  H1Light,
  H2Style,
  H2,
  H2Light,
  H3Style,
  H3,
  H4Style,
  H4,
  PStyle,
  P,
  PLight,
  Highlight,
  Light,
  WrapTextBlock,
  Strong,
  Quote,
  QuoteGroup,
  Impact,
  BigTitleLight,
}
