import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: inherit;
  }
  html {
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(245, 246, 244, 0);
    background-color: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  body {
    color: transparent;
    scroll-behavior: smooth;
  }
  h1, h2, h3, h4, h5 {
    color: transparent;
  }
  a {
    color: #FF3D78;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }

  /* TODO: Remove this reset when transition-link is fixed */
  body .tl-wrapper.tl-wrapper-status--entered {
    transform: none !important;
  }
`

export default GlobalStyle
