import React, { useState } from "react"
import styled, { css } from "styled-components"
import tw from "tailwind.macro"
import StickyNav from "react-sticky-nav"
import LinkTo from "../components/LinkTo"
import { Container, Inner } from "../elements/Content"
import FLogo from "../images/logo-full.svg"
import { textStyleBase } from "../elements/Text"

const linkStyle = css`
  ${textStyleBase};
  ${tw`text-grey hover:text-grey-darkest`};
  font-weight: normal;
`

const NavLink = styled(LinkTo)`
  ${linkStyle};
  ${tw`text-grey hover:text-grey-darkest`};
  font-weight: normal;
`

// gatsby-link does not do hash hrefs!
// see: https://www.gatsbyjs.org/docs/gatsby-link/#recommendations-for-programmatic-in-app-navigation
const HashLink = styled.a`
  ${linkStyle};
  ${tw`text-grey hover:text-grey-darkest`};
  font-weight: normal;
`

const stickyNavStyles = css`
  ${tw`bg-grey-lightest z-30 w-full`}
  /* TODO: Remove this reset when transition-link is fixed */
  position: fixed !important;
  box-shadow: none;
  transition: background-color 0.15s ease-out;

  &.pinned {
    ${tw`bg-white`};
    border-bottom: solid 2px #f5f6f4;
  }
`
const StickyNavShadow = styled.div`
  height: 72px;
  width: 100%;
`

const Nav = styled(Container)`
  ${tw`py-4`};
  transition: height 0.17s ease-out;

  @media only screen and (max-width: 900px) {
    height: ${props => (props.open ? "72px" : "280px")};
  }
`
const NavInner = styled(Inner)`
  ${tw`flex items-center justify-between flex-wrap`}
`

const LogoWrapper = styled.div`
  ${tw`flex text-black hover:text-black mr-4`};
  height: 40px;
`

const Logo = styled(FLogo)`
  height: 100%;
`

const HamburgerIcon = styled.div`
  ${tw`relative`};
`

const lineStyles = css`
  ${tw`bg-grey`};
  height: 2px;
  width: 26px;
  transform-origin: left top;
  transition: transform 0.2s ease, opacity 0.2s;
`
const LineTop = styled.div`
  ${lineStyles};
  transform: ${props =>
    props.open ? "none" : "translateX(2px) rotate(45deg)"};
`

const LineMiddle = styled.div`
  ${lineStyles};
  margin-top: 5px;
  margin-bottom: 5px;
  opacity: ${props => (props.open ? "1" : "0")};
  transform: ${props => (props.open ? "none" : "translateX(-50%)")};
`

const LineBottom = styled.div`
  ${lineStyles};
  transform: ${props =>
    props.open ? "none" : "translateY(4px) rotate(-45deg)"};
`

const TriggerWrapper = styled.div`
  ${tw`block lg:hidden`}
`

const Trigger = styled.div`
  ${tw`flex items-center p-3 cursor-pointer`};

  &:hover {
    ${LineTop},
    ${LineMiddle},
    ${LineBottom} {
      ${tw`bg-grey-darkest`};
    }
  }
`

const hiddenSmallOnly = css`
  ${tw`hidden lg:flex`};
`
const ListWrapper = styled.div`
  ${tw`w-full block flex-grow lg:flex lg:items-center lg:w-auto`};
  ${props => props.open && hiddenSmallOnly};
`

const List = styled.div`
  ${tw`text-base lg:flex-grow text-right`}
`

const ListItemStyles = css`
  ${tw`cursor-pointer`};
  ${tw`mt-4 lg:mt-0`};
`

const ListItem = styled.span`
  ${ListItemStyles};
  ${tw`block lg:inline-block lg:mr-6`};
  ${tw`px-4 lg:px-0`};
  animation-delay: ${props => (props.index ? props.index * 0.1 : "0")}s;
`

const Navigation = () => {
  const [open, setOpen] = useState(true)

  return (
    <>
      <Nav open={open}>
        <NavInner>
          <NavLink href="/">
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          </NavLink>
          <TriggerWrapper>
            <Trigger onClick={() => setOpen(!open)}>
              <HamburgerIcon>
                <LineTop open={open} />
                <LineMiddle open={open} />
                <LineBottom open={open} />
              </HamburgerIcon>
            </Trigger>
          </TriggerWrapper>
          <ListWrapper open={open}>
            <List>
              <NavLink href="/team">
                <ListItem index={1}>Meet the Team</ListItem>
              </NavLink>
              <NavLink href="/case-studies">
                <ListItem index={2}>Case Studies</ListItem>
              </NavLink>
              <NavLink href="/blog">
                <ListItem index={3}>Blog</ListItem>
              </NavLink>
              <HashLink
                href="/#contact"
                onClick={() => {
                  setOpen(true)
                }}
              >
                <ListItem index={4}>Contact</ListItem>
              </HashLink>
            </List>
          </ListWrapper>
        </NavInner>
      </Nav>
    </>
  )
}

export default Navigation
export { NavLink }
