import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import { Container, Inner } from "../elements/Content"
import CircleIcon from "../elements/CircleIcon"
import { textStyleBase } from "../elements/Text"

const Footer = styled(Container)`
  ${tw`py-4`};
`

const FooterInner = styled(Inner)`
  ${tw`flex items-center justify-center lg:justify-between flex-wrap font-sans`};
`

const Copyright = styled.div`
  ${tw`text-base pt-1 text-grey-light text-center lg:text-left`};
`

const SocialWrapper = styled.div`
  ${tw`w-full lg:w-auto`};
`

const List = styled.div`
  ${tw`text-base text-center lg:text-left lg:flex-grow pt-1`}
`
const ListItem = styled.a`
  ${textStyleBase};
  ${tw`inline-block mt-4 lg:mt-0 lg:mr-4`};
  ${tw`px-4 lg:px-0`};
  ${tw`text-grey-light hover:text-white`};
`

const FooterContent = () => (
  <>
    <Footer>
      <FooterInner>
        <Copyright>©{new Date().getFullYear()} Featurist Ltd.</Copyright>
        <SocialWrapper>
          <List>
            <ListItem
              href="https://github.com/featurist"
              target="_blank"
              alt="Github @featurist"
            >
              <CircleIcon icon="github" size={42} />
            </ListItem>
            <ListItem
              href="https://twitter.com/featurists"
              target="_blank"
              alt="Twitter @featurists"
            >
              <CircleIcon icon="twitter" size={42} />
            </ListItem>
          </List>
        </SocialWrapper>
      </FooterInner>
    </Footer>
  </>
)

export default FooterContent
