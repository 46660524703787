import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"

const Wrapper = styled.svg`
  ${tw``};
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  ${props => (props.active ? `& > circle` : `&:hover > circle`)} {
    stroke-dashoffset: 0;
  }
`

const Circle = styled.circle`
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  transition: stroke-dashoffset 0.35s ease-out;
`

const icons = {
  chevronLeft: <path d="M15.249 6l-6.498 6.015L15.249 18" />,
  chevronRight: <path d="M8.751 6l6.498 6.015L8.751 18" />,
  close: <path d="M6.643 6.643l10.714 10.714M6.643 17.357L17.357 6.643" />,
  github: (
    <path d="M12 3.883a8.02 8.02 0 0 0-2.535 15.628c.401.074.53-.174.53-.385v-1.493c-2.23.485-2.695-.947-2.695-.947-.365-.926-.891-1.173-.891-1.173-.727-.498.056-.487.056-.487.805.056 1.229.826 1.229.826.715 1.226 1.876.872 2.333.667.072-.518.28-.872.51-1.072-1.781-.204-3.654-.892-3.654-3.963 0-.877.314-1.592.826-2.153-.083-.202-.357-1.018.078-2.122 0 0 .674-.215 2.206.822A7.668 7.668 0 0 1 12 7.761a7.69 7.69 0 0 1 2.009.27c1.531-1.037 2.203-.822 2.203-.822.436 1.104.162 1.92.079 2.122.514.561.825 1.277.825 2.153 0 3.079-1.876 3.758-3.661 3.956.287.249.55.737.55 1.485v2.201c0 .213.128.463.535.384A8.02 8.02 0 0 0 12 3.883z" />
  ),
  twitter: (
    <path d="M18.505 7.965a5.29 5.29 0 0 1-1.533.42 2.67 2.67 0 0 0 1.174-1.477 5.352 5.352 0 0 1-1.695.648 2.672 2.672 0 0 0-4.549 2.434A7.578 7.578 0 0 1 6.4 7.202a2.674 2.674 0 0 0 .826 3.564 2.654 2.654 0 0 1-1.208-.334c-.03 1.236.857 2.393 2.141 2.651a2.68 2.68 0 0 1-1.206.045 2.671 2.671 0 0 0 2.494 1.854 5.367 5.367 0 0 1-3.952 1.106 7.556 7.556 0 0 0 4.091 1.199c4.957 0 7.757-4.186 7.587-7.94a5.418 5.418 0 0 0 1.332-1.382z" />
  ),
  linkedin: (
    <path
      d="M8.187 7.112c0 .75-.602 1.358-1.346 1.358a1.352 1.352 0 0 1-1.347-1.358c0-.749.603-1.358 1.347-1.358s1.346.609 1.346 1.358zm.011 2.444H5.483v8.69h2.715v-8.69zm4.335 0H9.835v8.69h2.699v-4.562c0-2.536 3.274-2.744 3.274 0v4.562h2.709v-5.502c0-4.28-4.845-4.124-5.984-2.018v-1.17z"
      fillRule="nonzero"
    />
  ),
}

const CircleIcon = ({ icon, size, style, active, fill }) => (
  <Wrapper viewBox="0 0 24 24" size={size} style={style} active={active}>
    <Circle
      cx="12"
      cy="12"
      r="11"
      fill={fill}
      stroke="currentColor"
      strokeWidth="0.75"
    />
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="0.55"
    >
      {icons[icon]}
    </g>
  </Wrapper>
)

export default CircleIcon

CircleIcon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)),
  size: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.string),
  active: PropTypes.bool,
  fill: PropTypes.string,
}

CircleIcon.defaultProps = {
  icon: "close",
  size: 54,
  style: {},
  active: false,
  fill: "none",
}
