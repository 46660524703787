import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"

const App = styled.div`
  ${tw`flex justify-center items-center w-full flex-col overflow-hidden`};
`

const AppWrapper = ({ children, ...props }) => (
  <>
    <App {...props}>{children}</App>
  </>
)

export default AppWrapper

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
