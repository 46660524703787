import styled from "styled-components"
import tw from "tailwind.macro"

const Container = styled.div`
  ${tw`w-full relative px-6 md:px-12 lg:px-24 xxl:px-32`};
`

const Content = styled(Container)`
  ${tw`bg-white`};
  ${tw`flex flex-wrap justify-center items-center`};
  ${tw`py-6 md:py-12`};
  ${tw`text-center lg:text-left`};
`

const ContentTop = styled(Content)`
  ${tw`items-start`};
`

const ContentFull = styled(Content)`
  ${tw`p-0 md:p-0 lg:p-0 xxl:px-0`};
`

const Inner = styled.div`
  ${tw`w-full mx-auto text-center lg:text-left relative`};
  ${props => (props.condensed ? tw`max-w-condensed` : tw`max-w-inner`)};
`

export default Content
export { Container, ContentTop, ContentFull, Inner }
