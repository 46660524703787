/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "typeface-eb-garamond"

import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import tw from "tailwind.macro"
import GlobalStyle from "../styles/global"
import Navigation from "../views/Navigation"
import FooterContent from "../views/FooterContent"
import blueBlockStyle from "../styles/blueBlock"
import { textSizeLarge } from "../elements/Text"

const FooterBackground = styled.div`
  ${blueBlockStyle}
`

const Announcement = styled.div`
  height: 54px;
  ${textSizeLarge};
  filter: opacity(30%);
  ${tw`text-grey-lightest bg-pink flex items-center justify-center`};
`

const Layout = ({ footer = true, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    // eslint-disable-next-line no-unused-vars
    render={data => (
      <>
        <GlobalStyle />
        <Announcement>ARCHIVED</Announcement>
        <Navigation />
        {children}
        {footer && (
          <FooterBackground>
            <FooterContent />
          </FooterBackground>
        )}
      </>
    )}
  />
)

Layout.propTypes = {
  footer: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  footer: true,
}

export default Layout
